































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class DrawerMenu extends Vue {
  @Prop() userType!: string;

  private menu: any = [];
  private menuGroups: any = [];

  private created() {
    this.menu = [];
    if (this.userType === "admin") {
      this.menuGroups = [
        {
          icon: "mdi-face-agent",
          label: "C/S",
          menus: [
            {
              label: "C/S현황",
              link: { name: "admin.cs.main" },
            },
          ],
        },
        {
          icon: "mdi-account-star",
          label: "고객",
          menus: [
            {
              label: "고객관리",
              link: { name: "admin.client.main" },
            },
          ],
        },
        {
          icon: "mdi-inbox-multiple",
          label: "재고현황",
          menus: [
            {
              label: "판매제품",
              link: { name: "admin.stock.selling" },
            },
            {
              label: "렌트제품",
              link: { name: "admin.stock.rent" },
            },
          ],
        },
        {
          icon: "mdi-toolbox",
          label: "제품",
          menus: [
            {
              label: "카테고리",
              link: { name: "admin.product.category" },
            },
            {
              label: "제품관리",
              link: { name: "admin.product.product" },
            },
          ],
        },
      ];
    } else if (this.userType === "partner") {
      this.menuGroups = [
        {
          icon: "mdi-inbox-multiple",
          label: "재고현황",
          menus: [
            {
              label: "판매제품",
              link: { name: "partner.stock.selling" },
            },
            {
              label: "렌트제품",
              link: { name: "partner.stock.rent" },
            },
          ],
        },
        {
          icon: "mdi-account-star",
          label: "고객",
          menus: [
            {
              label: "고객관리",
              link: { name: "partner.client.care" },
            },
          ],
        },
      ];
    } else if (this.userType === "client") {
      this.menuGroups = [
        {
          icon: "mdi-account-multiple",
          label: "재고관리",
          menus: [
            {
              label: "목록",
              link: { name: "members" },
            },
            {
              label: "근태 관리",
              link: { name: "member-attends" },
            },
          ],
        },
      ];
    }
  }
}
