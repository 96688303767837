












































import { Component } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import DrawerMenu from "@/components/menu/DrawerMenu.vue";

@Component({
  components: {
    DrawerMenu,
  },
})
export default class Main extends BaseView {
  private email = "";
  private password = "";
  private urlPath = "";

  data() {
    this.urlPath = window.location.pathname;
    return {
      homeLink: window.location.pathname,
      drawer: null,
      userType: this.urlPath.split("/")[1],
    };
  }
}
